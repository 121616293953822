import { FC, ReactNode, useContext } from 'react';
import { AuthContext } from '../auth/authorized-route';
import { Storage } from 'utils/storage-util';

export interface AuthorizedProps {
  authority: string;
  children?: ReactNode;
}

const Authorized: FC<AuthorizedProps> = props => {
  const admin = Storage.session.get('info').admin;
  const { authority, children } = props;
  const authInfo = useContext(AuthContext);
  const pathname = window.location.pathname.split('/').filter(e => e !== '');
  const menus = authInfo?.menus || [];
  const operateArr = menus.filter(e => e.parentMenuCode === pathname[0])?.[0]?.children.filter((e: any) => e.menuCode === pathname[1])?.[0]?.actions || [];

  return (
    <>
      {(operateArr.indexOf('all') > -1 || admin) ? children : (operateArr.indexOf(authority) > -1 && children)}
    </>
  );
};

export default Authorized;
