import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './app';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import initialize from 'config/store';
import setupAxiosInterceptors from 'config/axios-interceptor';
import LoadingBar from 'react-redux-loading-bar';
import {ConfigProvider} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import {bindActionCreators} from 'redux';
import {clearAuthentication} from './common/auth/authentication';


// 设置语言
moment.locale('zh-cn');

// mockServer.register();
const store = initialize();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => {
  // actions.clearAuthentication('login.error.unauthorized');
  // 重定向到登入页面。
  window.location.href = `/login`;
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LoadingBar className="loading-bar" />
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
