import { RouteComponentProps } from "@reach/router";
import { login } from "common/auth/authentication";
import { FC, ReactNode, useEffect } from "react";
import { connect } from "react-redux";
import { RootState } from "reducers";

export interface clearEntitiesListProps
    extends StateProps, DispatchProps, RouteComponentProps {
    children?: ReactNode;
    disableDefaultRequest?: any;
    getEntities?: any;
}

const LoginCode: FC<clearEntitiesListProps> = (props) => {

    const { login, isAuthenticated, navigate } = props;
    useEffect(() => {
        login({
            clientId: 'saber',
            scope: 'backend',
            tenant_id: '000000',
            code: getQueryParam('code'),
            appId: getQueryParam('appid'),
            agentId: getQueryParam('agentid'),
            grant_type: 'wecom',
        },true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // isAuthenticated && message.success('登录成功') && navigate && navigate('/');
        isAuthenticated && navigate && navigate('/');
    }, [isAuthenticated, navigate])

    function getQueryParam(paramName: string) {
        var search = window.location.search.substring(1);
        var params = search.split("&");
        for (var i = 0; i < params.length; i++) {
            var pair = params[i].split("=");
            if (pair[0] === paramName) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    };

    return <div style={{ width: '100vw', height: '100vh', textAlign: 'center', lineHeight: '100vh' }}>登录成功，即将返回经销商管理平台!</div>
};

const mapStateToProps = ({ authentication }: RootState) => ({
    isAuthenticated: authentication.isAuthenticated,
    mfaFactor: authentication.mfaFactor,
    mfaTemplateToken: authentication.mfaTemplateToken,
    phone: authentication.phone,
    errorMessage: authentication.errorMessage
});

const mapDispatchToProps = {
    login
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LoginCode);
