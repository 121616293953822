import {Storage} from 'utils/storage-util';
import {refreshAccessToken} from './authentication';

const AUTH_TOKEN_KEY = 'authentication-token';
const REFRESH_TOKEN_KEY = 'refresh-token';
const TIME_THRESHOLD = 60;

const isTokenExpired = (token: String): boolean => {
  if (!token) {
    // token为空，返回true
    return true;
  }

  const tokenList = token.split("."); //截取token，获取载体
  const tokenBody = tokenList[1].replace(/-/g, "+").replace(/_/g, "/");
  const userInfo = JSON.parse(decodeURIComponent(encodeURIComponent(window.atob(tokenBody))));
  const exp = userInfo?.exp || 0;
  const now = Date.now() / 1000;

  return exp - now < TIME_THRESHOLD;
}

export const getAccessToken = async (): Promise<String> => {
  const token = Storage.session.get(AUTH_TOKEN_KEY);
  if (isTokenExpired(token)) {
      // token过期
      const refreshToken = Storage.session.get(REFRESH_TOKEN_KEY)
      if (refreshToken) {
          // 存在refreshtoken，刷新令牌。
          const newToken = await refreshAccessToken(refreshToken);
          return newToken;
      }
      // if (refreshToken && !isTokenExpired(refreshToken)) {
      //   // refreshToken未过期，刷新token.
      //   const newToken = await refreshAccessToken(refreshToken);
      //   return newToken;
      // }

      return '';
  }

  // 检查token是否过期，过期但refresh token没有过期，刷新token。
  return token;
}

export const setAccessToken = (token: string) => {
  !!token && Storage.session.set(AUTH_TOKEN_KEY, token);
  !token && Storage.session.remove(AUTH_TOKEN_KEY);
};
